import { AppRoutes } from 'src/core/enums/app-routes.enum';
import { IconType } from 'src/core/enums/icon.enum';
import { AuthMode } from 'src/core/enums/permissions.enum';

export interface NavItemConfig {
  icon: keyof typeof IconType;
  text: string;
  path: string;
  isExactLink?: boolean;
  deactivated?: boolean;
  managerOnly?: boolean;
}

export const adminConfig: NavItemConfig[] = [
  {
    icon: IconType['graph-up'],
    text: 'Goals',
    path: AppRoutes.Goals.base,
  },
  {
    icon: IconType['users-group-rounded'],
    text: 'Team',
    path: AppRoutes.Team.base,
  },
  {
    icon: IconType['assess'],
    text: 'Assess',
    path: AppRoutes.PerformanceReview.base,
  },
  {
    icon: IconType['fine-tunning'],
    text: 'Settings',
    path: AppRoutes.OrganizationSettings.base,
  },
  {
    icon: IconType['pie-chart'],
    text: 'Metrics',
    path: AppRoutes.Dashboard.base,
  },
];

export const config: NavItemConfig[] = [
  {
    icon: IconType['graph-up'],
    text: 'Goals',
    path: AppRoutes.Goals.base,
  },
  {
    icon: IconType['assess'],
    text: 'Assess',
    path: AppRoutes.PerformanceReview.base,
  },
  {
    icon: IconType['feed'],
    text: 'Feed',
    path: AppRoutes.Feed.base,
  },
  {
    icon: IconType['square-forward'],
    text: 'Review',
    path: AppRoutes.Feedback.base,
  },
  {
    icon: IconType['call-chat'],
    text: 'Meet',
    path: AppRoutes.Meetings.base,
  },
  {
    icon: IconType['users-group-rounded'],
    text: 'Team',
    path: AppRoutes.Team.base,
  },
];

export const managerConfig = [
  ...config,
  {
    icon: IconType['pie-chart'],
    text: 'Metrics',
    path: AppRoutes.Dashboard.base,
  },
];

export const authSwitchConfig: {
  mode: AuthMode;
  icon: IconType;
  title: string;
  subtitle: string;
}[] = [
  {
    mode: AuthMode.Admin,
    icon: IconType['user-id'],
    title: 'Switch to admin view',
    subtitle: 'Overlook company and settings',
  },
  {
    mode: AuthMode.Manager,
    icon: IconType['user-speak'],
    title: 'Switch to manager view',
    subtitle: 'Manage your team',
  },
  {
    mode: AuthMode.Personal,
    icon: IconType['user-circle'],
    title: 'Switch to personal view',
    subtitle: 'Manage your own career',
  },
];
