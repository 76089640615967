<div
  class="side-nav-bar"
  *ngIf="authMode$ | async as authMode"
  [ngClass]="authMode"
>
  <div
    class="logo d-flex-column align-center justify-center white-bg"
    [ngClass]="{ 'white-bg': organization?.logo }"
    [routerLink]="appRoutes.home"
  >
    <img
      [class.default]="!organization?.logo"
      [src]="
        organization?.logo
          ? '/assets/logos/' + organization?.logo
          : '/assets/logo.svg'
      "
      alt="logo"
    />
  </div>

  <ul class="nav-links d-flex justify-center">
    <li
      routerLinkActive="active-link"
      *ngFor="let item of navConfig"
      [routerLink]="item.path"
      [routerLinkActiveOptions]="{ exact: item.isExactLink }"
      class="nav-link d-flex-center2"
    >
      <div class="wrap-icons">
        <app-icon [name]="item.icon"></app-icon>
      </div>

      <b>{{ item.text }}</b>
    </li>
  </ul>

  <div
    *ifPermission="[appPermission.authSwitchMode]; else navigateToProfile"
    class="nav-link profile-menu"
    (click)="isRolesPopupOpen = true"
  >
    <div class="wrap-icons">
      <app-icon [name]="getRoleIcon(authMode)"></app-icon>
    </div>

    <b>{{ authMode | titlecase }}</b>
  </div>

  <ng-container
    *ngIf="isRolesPopupOpen"
    [ngTemplateOutlet]="roleSwitchPopup"
    [ngTemplateOutletContext]="{ authMode }"
  ></ng-container>
</div>

<ng-template #roleSwitchPopup let-authMode="authMode">
  <div class="role-switch-container">
    <ng-template ngFor let-item [ngForOf]="authSwitchConfig">
      <div
        *ngIf="item.mode !== authMode"
        class="role-item d-flex-center"
        (click)="setAuthMode(item.mode)"
      >
        <app-icon [name]="item.icon"></app-icon>

        <div>
          <b>{{ item.title }}</b>
          <sub>{{ item.subtitle }}</sub>
        </div>
      </div>
    </ng-template>

    <hr class="separator" />

    <b
      class="link-item"
      [routerLink]="['/', appRoutes.Profile.base]"
      (click)="isRolesPopupOpen = false"
    >
      Profile
    </b>

    <div class="layer" (click)="isRolesPopupOpen = false"></div>
  </div>
</ng-template>

<ng-template #navigateToProfile>
  <div
    class="nav-link profile-menu"
    routerLinkActive="active-link"
    [routerLink]="appRoutes.Profile.base"
  >
    <div class="wrap-icons">
      <app-icon name="user-circle"></app-icon>
    </div>

    <b>Profile</b>
  </div>
</ng-template>
